<template>
  <div class="matercial_continar">
    <div class="continar">
      <el-form
        ref="form"
        class="form_css"
        :model="formData"
        inline
        :show-message="false"
        size="mini"
      >
        <el-form-item>
          <template>
            <div style="display: flex; align-items: center">
              <div style="margin-left: 10px; margin-right: 12px">图标上传</div>
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :auto-upload="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-change="handlePicUpload"
              >
                <img
                  v-if="formData.imageUrl"
                  :src="formData.imageUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="对应材质名称">
          <el-input
            v-model="formData.materical_name"
            label-width="100px"
            placeholder="请输入材质名称"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="warning"
            size="mini"
            @click="handleAdd"
            style="width: 80px"
            >添 加</el-button
          >
        </el-form-item>
      </el-form>
      <div class="table_css">
        <el-table
          :data="tableData"
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column label="序号" width="180">
            <template slot-scope="scope">
              <div>
                {{ scope.$index + 1 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="图标">
            <template slot-scope="scope">
              <div style="width: 100px;height: 100px;">
                <img :src="scope.row.imageUrl" alt=""  style="width: 100px;height: 100px;"/>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="materical_name" label="材质名称">
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <div>
                <el-button
                  @click="handleDelete(scope.$index, scope.row)"
                  type="text"
                  size="small"
                  style="color: red"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="btn_box_add">
        <el-button
          type="primary"
          size="mini"
          @click="handleSubmit"
          style="width: 120px; background-color: #007eff; border-color: #007eff"
          >提 交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { asyncUpload } from "@/api/upload";
import { submitAddTextureData } from "@/api/threeApi";
export default {
  data() {
    return {
      formData: {
        materical_name: "",
        imageUrl: "",
        file_id: "",
      },
      tableData: [],

    };
  },
  props: ["encryptStrData"],
  created() {
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.formData.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isType) {
        this.$message.error("上传图标只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图标大小不能超过 2MB!");
      }
      return isType && isLt2M;
    },

    handlePicUpload(file) {
      this.toLoading();
      const formData = new FormData();
      formData.append("file_type", "1");
      formData.append("file_data", file.raw);
      asyncUpload(formData).then((res) => {
        this.toLoading().close();
        if (res.code !== 200) return this.$message.error(res.msg);
        this.formData.imageUrl = res.data.file_path;
        this.formData.file_id = res.data.file_id;
        this.$message.success("上传成功");
      });
    },

    handleAdd() {
      if (this.formData.materical_name != "" && this.formData.file_id != "") {
        this.tableData.push(JSON.parse(JSON.stringify(this.formData)));
      } else {
        this.$message.error("请添加材质数据!");
      }
    },
    handleSubmit() {
      if (this.tableData.length == 0)
        return this.$message.error("请添加材质数据!");
      let postData = {
        eStr: this.encryptStrData,
        texture_json: JSON.stringify(this.tableData),
      };
      submitAddTextureData(postData).then((res) => {
        if(res.code !== 200) return  this.$message.error(res.msg);
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message:res.msg,
            type: "success",
            duration: 3000,
          });
          this.$emit('handleCloseDia')
          this.handleReset()
        }

      });
    },
    handleDelete(index, row) {
      this.tableData.splice(index, 1);
      this.$message({
        showClose: true,
        message: "数据删除成功!",
        type: "success",
        duration: 3000,
      });
    },
    handleReset() {
      this.tableData = [];
      this.formData.materical_name = "";
      this.formData.imageUrl = "";
      this.formData.file_id = "";
    },
  },
};
</script>

<style>
.matercial_continar {
  width: 100%;
  min-height: 200px;
  border: 1px solid #ccc;
}

.form_css {
  text-align: start !important;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.el-form-item {
  margin-bottom: 0px !important;
}
.el-form-item__label {
  padding-right: 5px !important;
}

.table_css {
  width: 98%;
  margin: 0 auto;
  border: 1px solid #ccc;
  margin-top: 10px;
}
.btn_box_add {
  margin: 10px 0;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px !important;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>