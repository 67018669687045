<template>
    <div id="3Dbox">
        <div id="container" ref="container">
            <div id="per" ref="per"></div>
        </div>
        <div id="controlBox" :class="{showSetBox:isShowSetBox, hideSetBox:!isShowSetBox}">
            <div style="width: 100%; background: rgba(48, 234, 255, 0.5); border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom: 1px solid rgb(3 131 113)">
                <Button name="toggleBtn" class="toggleBtn" @click="toggleControlBox" style="border: none; background: none; padding: 5px; cursor: pointer;">{{showBtnTitle}}</Button>
            </div>
            <div v-if="isShowSetBox" :class="controlBoxClass" style="width: 601px; height: 600px; background: rgba(109, 240, 255, 0.5); overflow: hidden; font-size: 12%;">
                <div style="width: 300px; height: 560px; float: left; border-right: 1px solid rgb(3 131 113);">
                    <div style="width: 100%; margin: 10px auto;">
                        <span style="color:brown; font-weight: bolder;">模型位置头设置:</span>
                        <div style="width: 100%; margin: 5px auto;">
                            <span>X轴:</span>
                            <input type="number" v-model="cameraDistanceX" @change="setCameraDistanceAll" step="0.1" @mouseover="mouseOverCameraDistanceAll" @mouseout="mouseOutCameraDistanceAll">
                        </div>
                        <div style="width: 100%; margin: 5px auto;">
                            <span>Y轴:</span>
                            <input type="number" v-model="cameraDistanceY" @change="setCameraDistanceAll" step="0.1" @mouseover="mouseOverCameraDistanceAll" @mouseout="mouseOutCameraDistanceAll">
                        </div>
                        <div style="width: 100%; margin: 5px auto;">
                            <span>Z轴:</span>
                            <input type="number" v-model="cameraDistanceZ" @change="setCameraDistanceAll" step="0.1" @mouseover="mouseOverCameraDistanceAll" @mouseout="mouseOutCameraDistanceAll">
                        </div>
                    </div>
                    
                    <div style="width: 100%; margin: 10px auto;">
                        <span style="color:brown; font-weight: bolder;">摄像头位置设置: </span>
                        <div style="width: 100%;">
                            <span>摄像头X轴: </span>
                            <input type="range" v-model="controlsX" min="-100" max="1000" step="0.1">
                            <input type="number" v-model="controlsX" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>摄像头Y轴: </span>
                            <input type="range" v-model="controlsY" min="-100" max="1000" step="0.1">
                            <input type="number" v-model="controlsY" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>摄像头Z轴: </span>
                            <input type="range" v-model="controlsZ" min="-100" max="1000" step="0.1">
                            <input type="number" v-model="controlsZ" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>视锥体垂直角度: </span>
                            <input type="range" v-model="cameraFov" min="-100" max="1000">
                            <input type="number" v-model="cameraFov" style="width: 50px;">
                        </div>
                        <!-- <div style="width: 100%;">
                            <span>摄像机视锥体远端面: </span>
                            <input type="range" v-model="cameraFar" min="2000" max="20000">
                            <span>{{ cameraFar }}</span>
                        </div> -->
                    </div>
                    
                    <div style="width: 100%; margin: 10px auto;">
                        <!-- <span>环境设置: <input type="checkbox" v-model="isOpenReflectivity" v-on:change="setEnvMapHdrName11"></span> -->
                        <span style="color:brown; font-weight: bolder;">环境设置: </span>
                        <div style="width: 100%;">
                            <span>环境贴图文件名: </span>
                            <input type="text" v-model="envMapHdrName" style="width: 120px;">
                            <button style="width: 50px; border: none; background-color:#5a94ff; color: #fff; font-size: 10px; line-height: 18px;" @click="setEnvMapHdrName">加载</button>
                        </div>
                        <div style="width: 100%;">
                            <span>调整反射强度: </span>
                            <input type="range" v-model="reflectivity" min="0" max="100" step="0.01">
                            <input type="number" v-model="reflectivity" style="width: 50px;" step="0.01">
                        </div>
                        <div style="width: 100%;">
                            <span>环境影响程度: </span>
                            <input type="range" v-model="envMapIntensity" min="0" max="100" step="0.01">
                            <input type="number" v-model="envMapIntensity" style="width: 50px;" step="0.01">
                        </div>
                        <div style="width: 100%;">
                            <span>HDR模式: </span>
                            <select v-model="toneMappingSelectedValue" style="width: 189px;">
                                <option v-for="option in toneMappingList" :value="option.value" :key="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                        <div style="width: 100%;">
                            <span>曝光度: </span>
                            <input type="range" v-model="toneMappingExposure" min="-100" max="500" step="0.1">
                            <input type="number" v-model="toneMappingExposure" style="width: 50px;">
                        </div>
                    </div>
                    
                    <div style="width: 100%; margin: 10px auto;">
                        <span style="color:brown; font-weight: bolder;">模型设置: </span>                        
                        <div style="width: 100%;">
                            <span>金属度: </span>
                            <input type="range" v-model="materialsMetalness" min="0" max="1" step="0.001">
                            <input type="number" v-model="materialsMetalness" style="width: 50px;" step="0.001">
                        </div>
                        <div style="width: 100%;">
                            <span>粗糙度: </span>
                            <input type="range" v-model="materialsRoughness" min="0" max="1" step="0.001">
                            <input type="number" v-model="materialsRoughness" style="width: 50px;" step="0.001">
                        </div>
                        <!-- <div style="width: 100%;">
                            <span>表面清漆层: </span>
                            <input type="range" v-model="materialsClearcoat" min="0" max="10000" step="0.1">
                            <input type="number" v-model="materialsClearcoat" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>表面粗糙度: </span>
                            <input type="range" v-model="materialsClearcoatRoughness" min="0" max="10000" step="0.1">
                            <input type="number" v-model="materialsClearcoatRoughness" style="width: 50px;">
                        </div> -->
                        <div style="width: 100%;">
                            <span>法线强度: </span>
                            <input type="range" v-model="materialsNormalMapType" min="-100" max="100" step="0.1">
                            <input type="number" v-model="materialsNormalMapType" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>OA强度: </span>
                            <input type="range" v-model="aoMapIntensity" min="-100" max="100" step="0.01">
                            <input type="number" v-model="aoMapIntensity" style="width: 50px;">
                        </div>
                    </div>
                    
                    <div style="width: 100%; margin: 10px auto;">
                        <span>照相机背景颜色设置: </span>
                        <div style="width: 100%;">
                            <span>颜色: </span>
                            <input type="color" v-model="sceneBackgroundColor">
                            <span>{{ sceneBackgroundColor }}</span>
                        </div>
                    </div>

                </div>

                <div style="width: 300px; height: 560px; float: left;">

                    <div style="width: 100%; margin: 10px auto;">
                        <span style="color:brown; font-weight: bolder;">环境光设置: </span>
                        <div style="width: 100%;">
                            <span>颜色: </span>
                            <input type="color" v-model="ambientLightColor">
                            <span>{{ ambientLightColor }}</span>
                        </div>
                        <div style="width: 100%;">
                            <span>强度: </span>
                            <input type="range" v-model="ambientLightIntensity" min="0" max="10000" step="0.1">
                            <input type="number" v-model="ambientLightIntensity" style="width: 50px;">
                        </div>
                    </div>

                    <div style="width: 100%; margin: 10px auto;">
                        <span style="color:brown; font-weight: bolder;">平行光设置: </span>
                        <div style="width: 100%;">
                            <span>颜色: </span>
                            <input type="color" v-model="directionalLightColor">
                            <span>{{ directionalLightColor }}</span>
                        </div>
                        <div style="width: 100%;">
                            <span>强度: </span>
                            <input type="range" v-model="directionalLightIntensity" min="0" max="10000" step="0.1">
                            <input type="number" v-model="directionalLightIntensity" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>X轴: </span>
                            <input type="range" v-model="directionalLightX" min="-100" max="1000" step="0.1">
                            <input type="number" v-model="directionalLightX" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>Y轴: </span>
                            <input type="range" v-model="directionalLightY" min="-100" max="1000" step="0.1">
                            <input type="number" v-model="directionalLightY" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>Z轴: </span>
                            <input type="range" v-model="directionalLightZ" min="-1000" max="1000" step="0.1">
                            <input type="number" v-model="directionalLightZ" style="width: 50px;">
                        </div>
                    </div>
                    
                    <div style="width: 100%; margin: 10px auto;">
                        <span style="color:brown; font-weight: bolder;">点光源设置: </span>
                        <div style="width: 100%;">
                            <span>颜色: </span>
                            <input type="color" v-model="pointLightColor">
                            <span>{{ pointLightColor }}</span>
                        </div>
                        <div style="width: 100%;">
                            <span>强度: </span>
                            <input type="range" v-model="pointLightIntensity" min="-100" max="10000" step="0.1">
                            <input type="number" v-model="pointLightIntensity" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>距离: </span>
                            <input type="range" v-model="pointLightDistance" min="-100" max="1000" step="0.1">
                            <input type="number" v-model="pointLightDistance" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>衰退: </span>
                            <input type="range" v-model="pointLightDecay" min="-100" max="100" step="0.1">
                            <input type="number" v-model="pointLightDecay" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>X轴: </span>
                            <input type="range" v-model="pointLightX" min="-100" max="1000" step="0.1">
                            <input type="number" v-model="pointLightX" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>Y轴: </span>
                            <input type="range" v-model="pointLightY" min="-100" max="1000" step="0.1">
                            <input type="number" v-model="pointLightY" style="width: 50px;">
                        </div>
                        <div style="width: 100%;">
                            <span>Z轴: </span>
                            <input type="range" v-model="pointLightZ" min="-1000" max="1000" step="0.1">
                            <input type="number" v-model="pointLightZ" style="width: 50px;">
                        </div>
                    </div>

                </div>
                
                
                <div class="btn_box">
                    <Button class="submitBtn btn1" @click="setBtn">提交</Button>
                    <Button class="submitBtn" @click="handleSetMaterial">材质设置</Button>
                </div>
            </div>
        </div>
        <!-- 3D模型皮肤控制器 -->
        <div id="skinImgControlBox">
            <ul v-if="skinImgData">
                <li v-for="(val, index) in skinImgData" :key="index" @click="changeSkinImg(val.materical_name,index)" :data-name="val.materical_name" :class="active == index?'active_css':'li_css'">
                    <img :src="val.imageUrl"/>
                </li>
            </ul>
        </div>
        <!-- 材质设置弹窗 -->
        <div>
            <el-dialog
            title="材质设置"
            :visible.sync="dialogMaterial"
            width="50%"
            :before-close="handleClose">
              <material :encryptStrData="encryptStr" @handleCloseDia="handleClose"></material>
            </el-dialog>
        </div>
    </div>
</template>
<script>
  import * as THREE from "three";
  import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
  import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
  import { getThreeParams ,getThreeModelData} from "@/api/threeApi";
  import { base64_decode ,_utf8_encode,encryptByDES } from '@/utils/encrypt'
  import material from "./material.vue";
  import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
  export default {
    name: "de3D",
    components: {
        material
    },
    data() {
        return {
            isShowSetBox:false,
            sliderValue: 50, // 设置初始滑块值
            fbxFilePath: null,
            scene: null,
            camera: null,
            renderer: null,
            mesh: null,
            loader: null,
            height: null,
            width: null,
            filePath: null,
            fbxFileName: null,
            controls: null,
            per: null,
            cameraDistanceX:-100,
            cameraDistanceY: 74,
            cameraDistanceZ: 10,
            cameraFov: 45,//摄像机视锥体垂直视野角度
            //cameraFar: 6000,//摄像机视锥体远端面
            controlsX: 0,
            controlsY: 0,
            controlsZ: 0,
            encryptStr: '',
            isNotSubmit: true,
            loading: null,
            imgType: 'jpg',
            imgTypeArr: ['jpg', 'png', 'gif', 'webp', 'psd'],
            // 环境光
            ambientLightColor: '#ffffff',// 颜色
            ambientLightIntensity: 25,// 强度
            // 直线（平行）光源
            directionalLightColor: '#ffffff',// 颜色
            directionalLightIntensity: 3,// 强度
            directionalLightX: 0,
            directionalLightY: 200,
            directionalLightZ: 100,
            // 点光源
            pointLightColor: '#ffffff',// 颜色
            pointLightIntensity: 0,// 强度
            pointLightDistance: 0,//光源照射的最大距离
            pointLightDecay: 2,//沿着光照距离的衰退量
            pointLightX: 0,
            pointLightY: 10,
            pointLightZ: 80,
            // 照相机背景颜色
            sceneBackgroundColor:'#eaeaea',
            ambientLight: null,
            pointLight: null,
            dirLight: null,

            controlBoxLeft: 0,
            controlBoxTop: 0,

            showControlBox: true,
            controlBoxClass: 'show-control-box', // 默认控制器板块动画

            skinImgPath: null,// 所有贴图文件夹名称
            mixer: null,
            action: null,
            clock: new THREE.Clock(),
            fbxObject: null,
            active:0,
            skinImgData: null,
            // 材质设置flag
            dialogMaterial: false,
            isAnimating: false,
            longPressFlag: false,// 设定一个标志来识别是点击还是长按
            longPressThreshold: 200,// 定义长按的时间阈值为0.2秒
            longPressTimer: null, // 定义一个定时器
            container: null,

            // 存储鼠标位置
            mouseX: 0,
            mouseY: 0,

            isOpenReflectivity: false,
            envMap: null,
            envMapIntensity : 0.5, //环境贴图对Mesh表面影响程度
            envMapHdrName: null,
            reflectivity : 0, // 调整反射强度

            isAutoSetCameraDistance: true,
            showBtnTitle: '收起',
            isShowBtnTitle: true,

            materialsMetalness : 0.0, // 金属度，0.0是石材，1.0是金属
            materialsRoughness : 1.0, // 粗糙度，0.0是平滑，1.0是粗糙
            materialsClearcoat : 0.5, // 物体表面清漆层或者说透明涂层的厚度
            materialsClearcoatRoughness : 0.2, // 透明涂层表面的粗糙度
            materialsNormalMapType : 1, // 法线凹凸程度
            aoMapIntensity : 0, // AO强度
            standardMaterial: null,
            toneMappingList: [
                { value: THREE.NoToneMapping, text: 'NoTone' },
                { value: THREE.LinearToneMapping, text: 'Linear' },
                { value: THREE.ReinhardToneMapping, text: 'Reinhard' },
                { value: THREE.CineonToneMapping, text: 'Cineon' },
                { value: THREE.ACESFilmicToneMapping, text: 'ACESFilmic' },
                { value: THREE.AgXToneMapping, text: 'AgX' },
                { value: THREE.NeutralToneMapping, text: 'Neutral' },
                { value: THREE.CustomToneMapping, text: 'Custom' },
            ],
            toneMappingExposure: 1, // 适当调整曝光度
            toneMappingSelectedValue: THREE.NoToneMapping,
        };
    },
    watch: {
        // OA强度
        aoMapIntensity(newValue) {
            let that = this;
            that.aoMapIntensity = newValue;
            that.fbxObject && that.fbxObject.traverse( function ( child ) {
                if (child.isMesh) {
                    that.envMapSet(child)
                }
            });
        },
        // 适当调整曝光度
        toneMappingExposure(newValue) {
            let that = this;
            that.toneMappingExposure = newValue;
            this.renderer.toneMappingExposure = this.toneMappingExposure; // 适当调整曝光度
        },
        // HDR模式
        toneMappingSelectedValue(newValue) {
            let that = this;
            that.toneMappingSelectedValue = newValue;
            this.renderer.toneMapping = this.toneMappingSelectedValue;//HDR模式
        },
        // 法线凹凸强度
        materialsNormalMapType(newValue) {
            let that = this;
            that.materialsNormalMapType = newValue;
            that.fbxObject && that.fbxObject.traverse( function ( child ) {
                if (child.isMesh) {
                    that.envMapSet(child)
                }
            });
        },
        // 透明涂层表面的粗糙度
        materialsClearcoatRoughness(newValue) {
            let that = this;
            that.materialsClearcoatRoughness = newValue;
            that.fbxObject && that.fbxObject.traverse( function ( child ) {
                if (child.isMesh) {
                    that.envMapSet(child)
                }
            });
        },
        // 物体表面清漆层或者说透明涂层的厚度
        materialsClearcoat(newValue) {
            let that = this;
            that.materialsClearcoat = newValue;
            that.fbxObject && that.fbxObject.traverse( function ( child ) {
                if (child.isMesh) {
                    that.envMapSet(child)
                }
            });
        },
        // 粗糙度，0.0是平滑，1.0是粗糙
        materialsRoughness(newValue) {
            let that = this;
            that.materialsRoughness = newValue;
            that.fbxObject && that.fbxObject.traverse( function ( child ) {
                if (child.isMesh) {
                    that.envMapSet(child)
                }
            });
        },
        // 金属度，0.0是石材，1.0是金属
        materialsMetalness(newValue) {
            let that = this;
            that.materialsMetalness = newValue;
            that.fbxObject && that.fbxObject.traverse( function ( child ) {
                if (child.isMesh) {
                    that.envMapSet(child)
                }
            });
        },
        envMapIntensity(newValue) {
            let that = this;
            that.envMapIntensity = newValue;
            that.fbxObject && that.fbxObject.traverse( function ( child ) {
                if (child.isMesh) {
                    that.renderer.physicallyCorrectLights = true;
                    that.envMapSet(child)
                }
            });
        },
        reflectivity(newValue) {
            let that = this;
            that.reflectivity = newValue;
            that.fbxObject && that.fbxObject.traverse( function ( child ) {
                if (child.isMesh) {
                    that.envMapSet(child)
                }
            });
        },
        // 点光源
        pointLightColor(newValue) {
            this.pointLight.color = new THREE.Color(newValue);
        },
        pointLightIntensity(newValue) {
            this.pointLight.intensity = newValue;
        },
        pointLightDistance(newValue) {
            this.pointLight.distance = newValue;
        },
        pointLightDecay(newValue) {
            this.pointLight.decay = newValue;
        },
        pointLightX(newValue) {
            this.pointLight.position.set( newValue, this.pointLightY, this.pointLightZ );
        },
        pointLightY(newValue) {
            this.pointLight.position.set( this.pointLightX, newValue, this.pointLightZ );
        },
        pointLightZ(newValue) {
            this.pointLight.position.set( this.pointLightX, this.pointLightY, newValue );
        },
        // 直线光源
        directionalLightColor(newValue) {
            this.dirLight.color = new THREE.Color(newValue);
        },
        directionalLightIntensity(newValue) {
            this.dirLight.intensity = newValue;
        },
        directionalLightX(newValue) {
            this.dirLight.position.set( newValue, this.directionalLightY, this.directionalLightZ );
        },
        directionalLightY(newValue) {
            this.dirLight.position.set( this.directionalLightX, newValue, this.directionalLightZ );
        },
        directionalLightZ(newValue) {
            this.dirLight.position.set( this.directionalLightX, this.directionalLightY, newValue );
        },
        // 环境光
        ambientLightColor(newValue) {
            this.ambientLight.color = new THREE.Color(newValue);
        },
        ambientLightIntensity(newValue) {
            this.ambientLight.intensity = newValue;
        },
        // 照相机背景颜色
        sceneBackgroundColor(newValue) {
            this.scene.background = new THREE.Color(newValue);
        },
        cameraFov(newValue) {
            this.camera.fov  = newValue;
            this.camera.updateProjectionMatrix(); // 更新摄像机的投影矩阵
        },
        // 摄像头三轴设置
        controlsX(newValue) {
            this.controlsX  = newValue;
            this.controls.target.set( newValue, this.controlsY, this.controlsZ );
            this.controls.update();
        },
        controlsY(newValue) {
            this.controlsY  = newValue;
            this.controls.target.set( this.controlsX, newValue, this.controlsZ );
            this.controls.update();
        },
        controlsZ(newValue) {
            this.controlsZ  = newValue;
            this.controls.target.set( this.controlsX, this.controlsY, newValue );
            this.controls.update();
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        // 添加监听器
        document.addEventListener('mousemove', this.onDocumentMouseMove, false);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        let that = this;
        document.title = '商品3D模型';
        const paramsOdj = that.getURLParams(window.location.href);
        that.fbxFileName = '';
        if (typeof(paramsOdj.fbxF) == 'undefined') {
            this.$message.error('缺少3D模型参数!');
            return ;
        }
        that.fbxFileName = paramsOdj.fbxF;
        if(typeof(paramsOdj.isSet) !== 'undefined') {
            const setBox = document.getElementById("controlBox");
            setBox.style.display = 'block',
            that.isShowSetBox = true
        }

        if (typeof(paramsOdj.imgT) !== 'undefined' && that.imgTypeArr.includes(paramsOdj.imgT)) {
            that.imgType = paramsOdj.imgT
        }
        
        that.filePath = './3D';
        that.fbxFilePath = that.filePath+'/'+that.fbxFileName+'/'+that.fbxFileName+'.fbx';

        that.height = window.innerHeight;
        that.width = window.innerWidth
        
        if (!that.fbxFileName) {
            return false;
        }

        that.per = that.$refs.per;
        that.per.style.width = 0.0*400 + "px";//进度条元素长度
        that.per.style.textIndent = 0.0*400 + 5 +"px";//缩进元素中的首行文本
        that.per.innerHTML =  "0%";//进度百分比
        
        that.container = document.createElement( 'div' );
        document.getElementById("3Dbox")?.appendChild(that.container);

        that.init()
    },
    methods: {
        init() {
            let that = this;
            that.camera = new THREE.PerspectiveCamera( that.cameraFov, that.width / that.height, 1, that.cameraFar );
            // 构建3D场景及渲染对象
            that.scene = new THREE.Scene();
            that.renderer = new THREE.WebGLRenderer();
            that.container.innerHTML = '';
            that.container.appendChild( that.renderer.domElement );
            // document.getElementById("container")?.appendChild(that.renderer.domElement);
            // 设置相机
            
            //that.camera.position.set( 0, 100, 100 );
            //that.camera.position.z = 50;

            // 创建控件对象
            that.controls = new OrbitControls(that.camera, that.renderer.domElement);
            that.controls.target.set( that.controlsX, that.controlsY, that.controlsZ );
            
            // 创建环境光
            this.ambientLight = new THREE.AmbientLight(this.ambientLightColor, this.ambientLightIntensity);
            this.scene.add(this.ambientLight);
            
            // 创建直线光源
            this.dirLight = new THREE.DirectionalLight(this.directionalLightColor, this.directionalLightIntensity);//光源，color:灯光颜色，intensity:光照强度
            this.dirLight.position.set( this.directionalLightX, this.directionalLightY, this.directionalLightZ );
            this.dirLight.castShadow = true;
            this.dirLight.shadow.camera.top = 300;
            this.dirLight.shadow.camera.bottom = - 500;
            this.dirLight.shadow.camera.left = - 500;
            this.dirLight.shadow.camera.right = 600;
            //this.camera.lookAt(0, 0, 0); // 让相机看向场景中心
            this.scene.add(this.dirLight);
            
            // 创建点光源
            this.pointLight = new THREE.PointLight(this.pointLightColor, this.pointLightIntensity, this.pointLightDistance, this.pointLightDecay) // 创建点光源
            this.pointLight.position.set( this.pointLightX, this.pointLightY, this.pointLightZ );
            this.scene.add(this.pointLight);
            
            this.scene.background = new THREE.Color(this.sceneBackgroundColor);
            
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.antialias = true;//渲染
            this.renderer.setSize(this.width, this.height); //设置渲染区尺寸

            this.setCameraDistanceAll()
            // renderer.setClearColor(0x00ff00); // 设置背景颜色为绿色
            // 设置曝光度
            this.renderer.physicallyCorrectLights = true;
            this.renderer.gammaOutput = true;
            this.renderer.shadowMap.enabled = true;
            this.renderer.toneMapping = this.toneMappingSelectedValue;//HDR模式
            this.renderer.toneMappingExposure = this.toneMappingExposure; // 适当调整曝光度
            this.renderer.outputEncoding = THREE.sRGBEncoding;

            that.handleEncryptByDES();
            that.fetchData();
            that.controlBoxTheXAndY();
        },
        // 时时刻刻渲染模型场景
        render() {
            requestAnimationFrame(this.render);
            if (this.mixer) {
                let delta = this.clock.getDelta();
                this.mixer.update(delta);
            }
            this.renderer.render(this.scene, this.camera);//执行渲染操作、指定场景、相机作为参数
            // 实时获取模型位置
            if (this.isAutoSetCameraDistance) {
                this.cameraDistanceX = this.roundToDecimalPlace(this.camera.position.x,1)
                this.cameraDistanceY = this.roundToDecimalPlace(this.camera.position.y,1)
                this.cameraDistanceZ = this.roundToDecimalPlace(this.camera.position.z,1)
                // this.updateLightPosition();
            }

        },
        // 更新光源位置
        updateLightPosition() {
            // 假设你有一个控制的相机(camera)
            // 使用鼠标位置和相机的方向来计算光源的新位置
            const vector = new THREE.Vector3(this.mouseX, this.mouseY, 0.5).unproject(this.camera);
            this.pointLight.position.copy(vector);
        },
        // 四舍五入到指定的小数位数
        roundToDecimalPlace(number, decimalPlaces) {
            const factor = Math.pow(10, decimalPlaces);
            return Math.round(number * factor) / factor;
        },
        setEnvMapHdrName() {
            this.openReflectivity();
            //if(this.envMapHdrName && this.envMapHdrName != 'null') this.openReflectivity()
        },
        // 环境贴图加载
        openReflectivity(){
            let that = this;
            if(that.envMapHdrName && that.envMapHdrName != 'null') {
                that.$refs.container.style.display = 'block';
                const loader = new RGBELoader();
                that.envMap = loader.load(that.filePath+'/'+that.fbxFileName+'/'+that.envMapHdrName+'.hdr', (texture) => {
                    texture.mapping = THREE.EquirectangularReflectionMapping;
                    texture.wrapS = THREE.RepeatWrapping
                    texture.wrapT = THREE.ClampToEdgeWrapping
                    that.scene.background = texture;
                    that.scene.environment = texture;
                    that.render()
                    that.$refs.container.style.display = 'none';
                }, (xhr) => {
                    let percent = xhr.loaded / xhr.total;
                    that.per.style.width = percent * 400 + "px"; //进度条元素长度
                    that.per.style.textIndent = percent * 400 + 5 + "px"; //缩进元素中的首行文本
                    that.per.innerHTML = Math.floor(percent * 100) + '%'; //进度百分比
                }, (error) => {
                    that.$refs.container.style.display = 'none';
                });
            } else {
                that.$refs.container.style.display = 'none';
                if(that.envMap) {
                    that.fbxObject.traverse( function ( child ) {
                        if (child.material !== undefined && child.material.envMap !== undefined) {
                            child.material.envMap = null; // 应用等距柱投影贴图
                            that.scene.background = new THREE.Color(that.sceneBackgroundColor);
                            if (child.isMesh) {
                                that.envMapSet(child)
                            }
                        }
                        
                    });
                    that.envMap = null;
                }
            }
            
        },
        // 加载模型或
        fbxModel() {
            let that = this;
            if(that.fbxObject) {
                that.scene.remove(that.fbxObject);
                if (that.fbxObject.geometry) {
                    that.fbxObject.geometry.dispose();
                }
                if (that.fbxObject.material) {
                    that.fbxObject.material.dispose();
                }
            }
            that.removeLongPressEventListener()

            // 环境贴图加载
            if(that.envMapHdrName) {
                that.openReflectivity();
            }
            
            that.loader =  new FBXLoader();
            that.loader.load(that.fbxFilePath, function(object) {
                that.fbxObject = object
                let child_num = 1;

                that.fbxObject.traverse( function ( child ) {
                    if (child.isMesh) {
                        // 环境贴图反射渲染
                        if (that.envMap) {
                            that.envMapSet(child)
                        }
                        // 设置材质为金属材质
                        that.standardMaterial = new THREE.MeshStandardMaterial({
                            metalness: that.materialsMetalness,// 金属度从数据绑定获取
                            roughness: that.materialsRoughness
                        });
                        let defaultMap = child.material.map
                        child.material = that.standardMaterial;
                        child.material.map = defaultMap;

                        // 获取材质
                        const materials = Array.isArray(child.material) ? child.material : [child.material];
                        materials.forEach((material) => {
                            const mapImg = that.skinImgPath ? that.filePath+'/'+that.fbxFileName+'/'+ that.skinImgPath + '/' + 'Bsc_'+child.name+'.'+that.imgType : that.filePath+'/'+that.fbxFileName+'/'+'Bsc_'+child.name+'.'+that.imgType;
                            const metallicMapImg = that.skinImgPath ? that.filePath+'/'+that.fbxFileName+'/'+ that.skinImgPath + '/' + 'Met_'+child.name+'.'+that.imgType : that.filePath+'/'+that.fbxFileName+'/'+'Met_'+child.name+'.'+that.imgType;
                            const roughnessMapImg = that.skinImgPath ? that.filePath+'/'+that.fbxFileName+'/'+ that.skinImgPath + '/' + 'Rns_'+child.name+'.'+that.imgType : that.filePath+'/'+that.fbxFileName+'/'+'Rns_'+child.name+'.'+that.imgType;
                            const normalMapImg = that.skinImgPath ? that.filePath+'/'+that.fbxFileName+'/'+ that.skinImgPath + '/' + 'Nom_'+child.name+'.'+that.imgType : that.filePath+'/'+that.fbxFileName+'/'+'Nom_'+child.name+'.'+that.imgType;
                            
                            const aoMapImg = that.skinImgPath ? that.filePath+'/'+that.fbxFileName+'/'+ that.skinImgPath + '/' + 'AO_'+child.name+'.'+that.imgType : that.filePath+'/'+that.fbxFileName+'/'+'AO_'+child.name+'.'+that.imgType;
                            // 创建Metallic roughness maps
                            // new THREE.TextureLoader().load(mapImg, (texture)=>{
                                
                            //     texture.magFilter = THREE.NearestFilter;
                            //     texture.minFilter = THREE.LinearMipmapLinearFilter;
                            //     material.map.source = texture.source;// 更换材质贴图
                            //     material.map.matrixAutoUpdate = false
                            //     material.needsUpdate  = true;// 确保材质更新
                            // });
                            
                            // 应用Metallic roughness maps
                            material.metalnessMap = new THREE.TextureLoader().load(metallicMapImg);
                            material.roughnessMap = new THREE.TextureLoader().load(roughnessMapImg);// 粗糙度贴图
                            material.aoMap = new THREE.TextureLoader().load(aoMapImg);// AO贴图
                            material.aoMapIntensity = that.aoMapIntensity;
                            // 确保材质使用Metallic工作流
                            material.normalMap = new THREE.TextureLoader().load(normalMapImg, ()=>{
                                if(child_num >= materials.length) {
                                    // Math.floor:小数加载进度取整
                                    that.per.innerHTML = '100%'; //进度百分比
                                    that.$refs.container.style.display = 'none';
                                }
                                child_num++;
                            });// 法线贴图
                            material.normalScale.set(that.materialsNormalMapType, that.materialsNormalMapType);

                            // material.normalMapType = -1// 法线强度
                        });
                        
                        // 确保材质透明度支持
                        child.material.transparent = true;
                        // 设置alphaTest或opacity以实现透明效果
                        child.material.alphaTest = 0.5; // 设置为你需要的透明度阈值
                    }
                });
                that.scene.add(that.fbxObject);
                
                // 模型动画
                let animation = that.fbxObject.animations[0];
                if (animation !== undefined) {
                    that.mixer = new THREE.AnimationMixer(that.fbxObject);
                    that.action = that.mixer.clipAction(animation);
                    that.action.clampWhenFinished = true;

                    // 设置长按点击事件禁止触发点击事件
                    that.setLongPressEventListener();
                    if(that.isAnimating) that.resetAnimation()
                }
                // object.translateY(-80);
            }, (xhr) => {
                let percent = xhr.loaded / xhr.total;
                if (percent >=0.8) percent = 0.98;
                that.per.style.width = percent * 400 + "px"; //进度条元素长度
                that.per.style.textIndent = percent * 400 + 5 + "px"; //缩进元素中的首行文本
                that.per.innerHTML = Math.floor(percent * 100) + '%'; //进度百分比
            }, (error) => {
                console.error('An error:', error);
            })
        },
        envMapSet(child) {
            // 获取材质
            const materials = Array.isArray(child.material) ? child.material : [child.material];
            materials.forEach((material) => {
                material.needsUpdate = true; // 因为我们改变了材质，所以需要更新
                material.envMap = this.envMap; // 应用等距柱投影贴图
                material.envMapIntensity = this.envMapIntensity //环境贴图对Mesh表面影响程度
                material.reflectivity = this.reflectivity // 调整反射强度
                material.side = THREE.DoubleSide
                // material.combine = THREE.MixOperation; // 使用混合操作来结合环境映射和原始材质颜色
                material.metalness = this.materialsMetalness; // 金属度，0.0是石材，1.0是金属
                material.roughness = this.materialsRoughness; // 粗糙度，0.0是平滑，1.0是粗糙
                material.aoMapIntensity = this.aoMapIntensity; // AO强度
                child.clearcoat = this.materialsClearcoat; // 物体表面清漆层或者说透明涂层的厚度
                //child.clearcoatRoughness = this.materialsClearcoatRoughness; // 透明涂层表面的粗糙度
                material.normalScale.set(this.materialsNormalMapType, this.materialsNormalMapType);// 法线凹凸程度
                material.needsUpdate = true; // 因为我们改变了材质，所以需要更新
            });
        },
        // 获取路由的参数数据
        getURLParams(url) {
            var paramResult = {};
            let param = url.split('#')[0];           //获取#/之前的字符串
            let paramContent = '';
            let paramsArray = '';
            if(param) {
                paramContent = param.split('?')[1];  //获取?之后的参数字符串
            }
            if(paramContent) {
                paramsArray = paramContent.split('&');    //参数字符串分割为数组
                //遍历数组，拿到json对象
                paramsArray.forEach((item, index, paramsArray) => {
                    paramResult[paramsArray[index].split('=')[0]] = paramsArray[index].split('=')[1];
                })
            }
            return paramResult;
        },
        // 实时设置视图尺寸
        handleResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            // 重置渲染器宽高比
            this.renderer.setSize(this.width, this.height);
            // 重置相机宽高比
            this.camera.aspect = this.width / this.height;
            // 更新相机的投影矩阵
            this.camera.updateProjectionMatrix();
        },
        // 设置相机（摄像机）距离目标的新位置
        setCameraDistanceAll() {
            this.camera.position.set(this.cameraDistanceX, this.cameraDistanceY, this.cameraDistanceZ);
            this.camera.lookAt(0, 0, 0); // 让相机看向场景中心
            this.render();
        },
        mouseOverCameraDistanceAll() {
            this.isAutoSetCameraDistance = false
        },
        mouseOutCameraDistanceAll() {
            this.isAutoSetCameraDistance = true
        },
        // 实时设置模型的x、y、z轴值来重新更新渲染模型
        setControlsAll() {
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.target.set( this.controlsX, this.controlsY, this.controlsZ );
            this.controls.update();
            this.render();
        },
        // 监听模型点击事件
        setLongPressEventListener() {
            let that = this
            // 给元素添加点击事件
            that.renderer.domElement.addEventListener('mousedown', function(e) {
                that.longPressTimer = setTimeout(function() {
                    that.longPressFlag = true;
                }, that.longPressThreshold);
            });

            // 给元素添加松开事件
            that.renderer.domElement.addEventListener('mouseup', function(e) {
                clearTimeout(that.longPressTimer);
                if (!that.longPressFlag) that.handleClick();
                that.longPressFlag = false;
            });
        },
        // 移除监听模型点击事件
        removeLongPressEventListener() {
            let that = this
            
            that.renderer.domElement.removeEventListener('mousedown', function(){});
            that.renderer.domElement.removeEventListener('mouseup', function(){});

            clearTimeout(that.longPressTimer);
            that.longPressFlag = false
            that.longPressTimer = null
        },
        handleEncryptByDES() {
            if (!this.fbxFileName) {
                this.$alert('缺少商品编号', '提示', {
                confirmButtonText: '确定'
                });
                return false;
            }
            let msg = base64_decode(this.fbxFileName)
            this.encryptStr = encryptByDES(msg)
        },
        // 获取商品模型数据
        fetchData() {
            let that = this
            let params = {
                eStr: that.encryptStr
            }
            getThreeParams(params).then(response => {
                if (response.code == 200 && Object.keys(response.data).length > 0) {
                    let myData = response.data
                    that.cameraDistanceX = myData.camera_distance_x
                    that.cameraDistanceY = myData.camera_distance_y
                    that.cameraDistanceZ = myData.camera_distance_z
                    that.controlsX = typeof(myData.controls_x) !== 'undefined' ? myData.controls_x : 0
                    that.controlsY = typeof(myData.controls_y) !== 'undefined' ? myData.controls_y : 0
                    that.controlsZ = typeof(myData.controls_z) !== 'undefined' ? myData.controls_z : 0
                    that.ambientLightColor = myData.json_data && typeof(myData.json_data.ambientLightColor) !== 'undefined' ? myData.json_data.ambientLightColor : "#FFFFFF"
                    that.ambientLightIntensity = myData.json_data && typeof(myData.json_data.ambientLightIntensity) !== 'undefined' ? myData.json_data.ambientLightIntensity : that.ambientLightIntensity
                    that.directionalLightColor = myData.json_data && typeof(myData.json_data.directionalLightColor) !== 'undefined' ? myData.json_data.directionalLightColor : "#FFFFFF"
                    that.directionalLightIntensity = myData.json_data && typeof(myData.json_data.directionalLightIntensity) !== 'undefined' ? myData.json_data.directionalLightIntensity : that.directionalLightIntensity
                    that.pointLightColor = myData.json_data && typeof(myData.json_data.pointLightColor) !== 'undefined' ? myData.json_data.pointLightColor : "#FFFFFF"
                    that.pointLightIntensity = myData.json_data && typeof(myData.json_data.pointLightIntensity) !== 'undefined' ? myData.json_data.pointLightIntensity : that.pointLightIntensity
                    that.pointLightDistance = myData.json_data && typeof(myData.json_data.pointLightDistance) !== 'undefined' ? myData.json_data.pointLightDistance : that.pointLightDistance
                    that.pointLightDecay = myData.json_data && typeof(myData.json_data.pointLightDecay) !== 'undefined' ? myData.json_data.pointLightDecay : that.pointLightDecay

                    that.cameraFov = myData.json_data && typeof(myData.json_data.cameraFov) !== 'undefined' ? myData.json_data.cameraFov : 0
                    that.directionalLightX = myData.json_data && typeof(myData.json_data.directionalLightX) !== 'undefined' ? myData.json_data.directionalLightX : 0
                    that.directionalLightY = myData.json_data && typeof(myData.json_data.directionalLightY) !== 'undefined' ? myData.json_data.directionalLightY : 0
                    that.directionalLightZ = myData.json_data && typeof(myData.json_data.directionalLightZ) !== 'undefined' ? myData.json_data.directionalLightZ : 0
                    that.pointLightX = myData.json_data && typeof(myData.json_data.pointLightX) !== 'undefined' ? myData.json_data.pointLightX : 0
                    that.pointLightY = myData.json_data && typeof(myData.json_data.pointLightY) !== 'undefined' ? myData.json_data.pointLightY : 0
                    that.pointLightZ = myData.json_data && typeof(myData.json_data.pointLightZ) !== 'undefined' ? myData.json_data.pointLightZ : 0
                    that.reflectivity = myData.json_data && typeof(myData.json_data.reflectivity) !== 'undefined' ? myData.json_data.reflectivity : 0
                    that.toneMappingExposure = myData.json_data && typeof(myData.json_data.toneMappingExposure) !== 'undefined' ? myData.json_data.toneMappingExposure : that.toneMappingExposure
                    that.toneMappingSelectedValue = myData.json_data && typeof(myData.json_data.toneMappingSelectedValue) !== 'undefined' ? myData.json_data.toneMappingSelectedValue : that.toneMappingSelectedValue
                    that.materialsMetalness = myData.json_data && typeof(myData.json_data.materialsMetalness) !== 'undefined' ? myData.json_data.materialsMetalness : that.materialsMetalness
                    that.materialsRoughness = myData.json_data && typeof(myData.json_data.materialsRoughness) !== 'undefined' ? myData.json_data.materialsRoughness : that.materialsRoughness
                    that.materialsNormalMapType = myData.json_data && typeof(myData.json_data.materialsNormalMapType) !== 'undefined' ? myData.json_data.materialsNormalMapType : that.materialsNormalMapType
                    that.aoMapIntensity = myData.json_data && typeof(myData.json_data.aoMapIntensity) !== 'undefined' ? myData.json_data.aoMapIntensity : that.aoMapIntensity
                    that.envMapIntensity = myData.json_data && typeof(myData.json_data.envMapIntensity) !== 'undefined' ? myData.json_data.envMapIntensity : that.envMapIntensity
                    that.envMapHdrName = myData.json_data && typeof(myData.json_data.envMapHdrName) !== 'undefined' ? myData.json_data.envMapHdrName : ''
                    // that.isOpenReflectivity = myData.json_data && typeof(myData.json_data.isOpenReflectivity) !== 'undefined' ? myData.json_data.isOpenReflectivity : false
                    // // 皮肤切换————测试，正式删掉
                    // let skinImgArr = [
                    //                     {
                    //                         imageUrl: 'https://img1.baidu.com/it/u=2615448699,602630049&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                    //                         materical_name: 'green'

                    //                     },
                    //                     {
                    //                         imageUrl: 'https://wx4.sinaimg.cn/mw690/bf588bb0gy1h3w6d4h03yj20ku0logpo.jpg',
                    //                         materical_name: 'red'

                    //                     }
                    //                 ]
                    // // 皮肤切换————测试，正式删掉
                    // that.skinImgData = skinImgArr
                    // 皮肤切换————正式
                    if (typeof(myData.skinImgList) !== 'undefined' && myData.skinImgList) that.skinImgData = myData.skinImgList
                    that.setCameraDistanceAll()
                    // that.setControlsAll()

                }
                that.fbxModel();
                that.render();
            }).catch(error => {
                that.error = error;
                that.$alert(this.error, '提示', {
                    confirmButtonText: '确定'
                });
            });
        },
        // 保存模型配置数据
        setBtn() {
            if(!this.isNotSubmit) {
                return false
            }
            this.isNotSubmit = false
            let postData = {
                cX: this.cameraDistanceX,
                    cY: this.cameraDistanceY,
                    cZ: this.cameraDistanceZ,
                    conX: this.controlsX,
                    conY: this.controlsY,
                    conZ: this.controlsZ,
                    eStr: this.encryptStr,
                    json_data: {
                        // 环境光
                        ambientLightColor: this.ambientLightColor,// 颜色
                        ambientLightIntensity: this.ambientLightIntensity,// 强度
                        // 直线光源
                        directionalLightColor: this.directionalLightColor,// 颜色
                        directionalLightIntensity: this.directionalLightIntensity,// 强度
                        // 点光源
                        pointLightColor: this.pointLightColor,// 颜色
                        pointLightIntensity: this.pointLightIntensity,// 强度
                        pointLightDistance: this.pointLightDistance,//光源照射的最大距离
                        pointLightDecay: this.pointLightDecay,//沿着光照距离的衰退量
                        // 照相机背景颜色
                        sceneBackgroundColor:this.sceneBackgroundColor,
                        
                        cameraFov: this.cameraFov,
                        directionalLightX: this.directionalLightX,
                        directionalLightY: this.directionalLightY,
                        directionalLightZ: this.directionalLightZ,
                        pointLightX: this.pointLightX,
                        pointLightY: this.pointLightY,
                        pointLightZ: this.pointLightZ,
                        reflectivity: this.reflectivity,
                        toneMappingExposure: this.toneMappingExposure,
                        materialsMetalness: this.materialsMetalness,
                        materialsRoughness: this.materialsRoughness,
                        materialsNormalMapType: this.materialsNormalMapType,
                        aoMapIntensity: this.aoMapIntensity,
                        toneMappingSelectedValue: this.toneMappingSelectedValue,
                        envMapIntensity: this.envMapIntensity,
                        envMapHdrName: this.envMapHdrName ? this.envMapHdrName : '',
                    }
            }
            getThreeModelData(postData).then(res => {
                // this.data = JSON.parse(response.data);
                let typeStr = res.code == 200 ? 'success' : 'warning';
                let msgStr = res.code == 200 ? '设置成功' : '设置失败：'+res.msg;
                this.$message({
                    message: msgStr,
                    type: typeStr
                });
                this.isNotSubmit = true;
            }).catch(error => {
                this.isNotSubmit = true;
                this.error = error;
            });
        },
        // 控制控制面板拖拽效果
        controlBoxTheXAndY() {
            let that = this;
            let controlBox = document.getElementById("controlBox");
            controlBox.onmousedown = function (e) {
                // console.log('dededede',e.target.tagName.toLocaleLowerCase())
                // 以防按钮或者input被劫持，因此需加该判断
                if (e.target.tagName.toLocaleLowerCase() == 'div') {
                    let disX = e.clientX - controlBox.offsetLeft;//获取鼠标到controlBox left的距离
                    let disY = e.clientY - controlBox.offsetTop;//获取鼠标到controlBox top的距离
                    document.onmousemove = function (e) {//处理鼠标滑动过快，脱离controlBox，所以在document上添加事件
                        controlBox.style.left = e.clientX-disX+'px';//controlBox的left等于鼠标的位置-鼠标到controlBox左边的距离
                        controlBox.style.top = e.clientY-disY+'px';
                        that.controlBoxTheXrangeAndYrange(e, controlBox, disX, disY)
                    };
                    document.onmouseup = function () {//处理拖拽时鼠标滑到网址块区域，鼠标回来时controlBox依旧跟随鼠标，事件也加在document上，超出document就停止
                        //document.onmousedown = null;//去除点击事件
                        document.onmousemove = null;//去除移动事件，否则会“沾”在鼠标上
                    };
                    return false//在火狐老版本上会有浏览器bug会造成第二次以后拖拽产生重影，去除默认事件，可以去除浏览器影响。
                } else {
                    document.onmousedown = null;//去除点击事件
                }
            };
        },// 控制控制面板拖拽的范围不超出视图范围
        controlBoxTheXrangeAndYrange(e, controlBox, disX, disY) {
            let controlBoxMaxLeft = e.clientX-disX + controlBox.offsetWidth;
            let controlBoxMaxTop = e.clientY-disY + controlBox.offsetHeight;
            if(controlBoxMaxLeft >= this.width) {
                controlBox.style.left = this.controlBoxLeft
            }
            if(controlBoxMaxTop >= this.height) {
                controlBox.style.top = this.controlBoxTop
            }
            if((e.clientX-disX) <= 0) {
                controlBox.style.left = 0
            }
            if((e.clientY-disY) <= 0) {
                controlBox.style.top = 0
            }
            this.controlBoxLeft = controlBox.style.left
            this.controlBoxTop = controlBox.style.top
        },
        toggleControlBox(e) {
            this.showControlBox = !this.showControlBox;
            this.controlBoxClass = this.showControlBox ? 'show-control-box' : 'hide-control-box';
            this.showBtnTitle = this.isShowBtnTitle ? '展开' : '收起';
            this.isShowBtnTitle = !this.isShowBtnTitle;
        },
        changeSkinImg(skinImgPath,index){
            let that = this
            that.active = index
            that.skinImgPath = skinImgPath
            
            that.fbxObject.traverse( function ( child ) {
                if (child.isMesh) {
                    // 获取材质
                    let materials = Array.isArray(child.material) ? child.material : [child.material];
                    materials.forEach((material) => {
                        let mapImg = that.skinImgPath ? that.filePath+'/'+that.fbxFileName+'/'+ that.skinImgPath + '/' + 'Bsc_'+child.name+'.'+that.imgType : that.filePath+'/'+that.fbxFileName+'/'+'Bsc_'+child.name+'.'+that.imgType;
                        new THREE.TextureLoader().load(mapImg, (texture)=>{
                            material.map = texture;// 更换材质贴图
                            material.map.matrixAutoUpdate = false
                            material.needsUpdate  = true;// 确保材质更新
                        });
                    });
                }
            });
        },
        // 材质设置
        handleSetMaterial(){
           this.dialogMaterial = true
        },
        handleClose(){
            this.dialogMaterial = false
            this.init()
        },
        handleClick() {
            this.action.stop(); // 停止播放
            this.action.time = this.isAnimating ? (this.action.getClip()).duration : 0; // 设置动画到总时长
            this.action.play();
            this.action.timeScale = this.isAnimating ? -1 : 1; // 设置时间缩放因子为-1实现反向播放
            this.isAnimating = !this.isAnimating;
            this.action.loop = THREE.LoopOnce; // 设置动画只播放一次
        },
        resetAnimation() {
            this.action.time = this.isAnimating ? (this.action.getClip()).duration : 0; // 设置动画到总时长
            this.action.timeScale = this.isAnimating ? -1 : 1; // 设置时间缩放因子为-1实现反向播放
            this.isAnimating = !this.isAnimating;
        },
        // 监听鼠标移动事件
        onDocumentMouseMove(event) {
            // 将鼠标位置归一化为[-1, 1]的范围，并反转Y轴
            this.mouseX = (event.clientX / window.innerWidth) * 2 - 1;
            this.mouseY = -((event.clientY / window.innerHeight) * 2 - 1);
        }
        
    }
  };
  </script>
  
<style>
    @keyframes showControlLeft {
        0% {
            opacity: 0;
            transform: translateX(-100%) scale(0);
        }
        100% {
            opacity: 1;
            transform: translateX(0) scale(1);
        }
    }
    @keyframes hideControlLeft {
        0% {
            opacity: 1;
            transform: translateX(0) scale(1);
        }
        100% {
            opacity: 0;
            width: 0;
            height: 0;
            transform: translateX(-100%) scale(0);
        }
    }
    .show-control-box {
        display: block;
        animation: showControlLeft 0.5s forwards;
        
    }
    .hide-control-box {
        animation: hideControlLeft 0.5s forwards;
    }

    .btn_box{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 95%;
        margin:0 auto;
        margin-top: 20px;
    }
    .submitBtn {
        background-color: #42b983;
        border: none;
        color: white;
        padding: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 6px 2px;
        cursor: pointer;
        font-size: 12px;
        width: 45%;
    }
    .btn1{
        background-color: #007eff;
    }
    /* 进度条css样式 */
    #container {
        position: absolute;
        width: 400px;
        height: 16px;
        top: 50%;
        left:50%;
        margin-left: -200px;
        margin-top: -8px;
        border-radius: 8px;           
        border: 1px solid #009999;          
        overflow: hidden;
    }
    #per {
        height: 100%;
        width: 0px;
        background: #00ffff;
        color: #00ffff;
        line-height: 15px;          
    }
    .showSetBox {
        display: block;
    }
    .hideSetBox {
        display: none;
    }
    #controlBox{
        position: absolute;
        min-width: 50px;
        min-height: 50px;
    }
    /* 皮肤按钮css样式 */
    #skinImgControlBox {
        position: absolute;
        justify-content: center;
        align-items: center;
        left: 50%;
        bottom: 5%;
        transform: translateX(-50%);
    }
    #skinImgControlBox > ul{
        text-align: center;
        list-style: none;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        height: 30px;
    }
    #skinImgControlBox > ul > li{
        width: 30px;
        height: 30px;
        display: inline-block;
        padding: 0;
        margin: 0 5px;
        border-radius: 15px;
        overflow: hidden;
        cursor: pointer;
    }
    #skinImgControlBox > ul > li img{
        width: 100%;
        height: 100%;
    }
    .active_css{
        background-color: #fff;
        border: #dafeff 1px solid;
        box-shadow: 0 0 2px #fff,0 0 4px rgb(43, 230, 255);
    }
    .li_css{
        border: none; 
    }
    .el-dialog__body{
        padding: 20px !important;
        padding-top: 0px !important;
    }
  
</style>