import request from '@/utils/request'

/**
 * @description 文件同步上传
 * @param {Object} data 文件类型数据
 */
export function syncUpload(data) {
    return request({
        url: '/utils/uploadFile',
        method: 'post',
        data
    })
}


/**
 * @description 文件异步上传
 * @param {Object} data 文件类型数据
 * @returns
 */
export function asyncUpload(data) {
    return request({
        url: '/utils/uploadFile',
        method: 'post',
        data
    })
}
