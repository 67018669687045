import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { isString } from './validate'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, 
  timeout: 200000 // request timeout
})
console.log(process.env.VUE_APP_BASE_API)
// 请求设置
request.interceptors.request.use(
  config => {
   
    return config
  },
  error => {
    console.log(error) 
    return Promise.reject(error)
  }
)
request.interceptors.response.use(
  response => {
    const res = isString(response.data) ? JSON.parse(response.data) : response.data
      return res
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default request

