import request from '@/utils/request'

// 请求数据
// export function adminPage(params = {}) {
//     return request({
//         url: '/merchant/adminPage',
//         method: 'get',
//         params
//     })
// }

export function getThreeParams(params={}) {
    return request({
        url:'/market/getProduct3dData',
        method: 'get',
        params
    })
}

export function getThreeModelData(data) {
    return request({
        url:'/market/addProduct3d',
        method: 'post',
        data
    })
}

// 新增材质
export function submitAddTextureData(data) {
    return request({
        url:'/market/addTextureData',
        method: 'post',
        data
    })
}